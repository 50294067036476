import { createGlobalStyle, theme } from 'styles/styled';

const GlobalStyle = createGlobalStyle`
  *, *::before, *::after {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    border: 0;
    font: inherit;
    vertical-align: baseline;
  }
  
  * {
    -webkit-overflow-scrolling: touch;
    scrollbar-color: ${theme.palette.darkLight} transparent;
    scrollbar-width: 'thin';

    &::-webkit-scrollbar {
      height: 4px;
      width: 4px;
    }
    
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }

    &::-webkit-scrollbar-thumb {
      background-color: ${theme.palette.darkLight};
    }

    &::-webkit-scrollbar-thumb:vertical {
      min-height: 5vw;
    }

    &::-webkit-scrollbar-thumb:horizontal {
      min-width: 5vh;
    }
  }

  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
    display: block;
  }
  html, body {
    font-family: 'Roboto', sans-serif;
    font-display: fallback;
    font-weight: ${theme.fontWeight.light};
    line-height: 1;
  }
  ol, ul {
    list-style: none;
  }
  blockquote, q {
    quotes: none;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
    content: '';
    content: none;
  }
  table {
    border-collapse: collapse;
    border-spacing: 0;
  }
  input {
    -webkit-border-radius: 0;
    border-radius: 0;
    filter: none;
  }
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type=number] {
    -moz-appearance: textfield;
  }
  /* remove background color for autocomplete */
  input:-webkit-autofill,
  input:-webkit-autofill:hover,
  input:-webkit-autofill:focus,
  input:-webkit-autofill:active {
    -webkit-transition: color 9999s ease-out, background-color 9999s ease-out;
    -webkit-transition-delay: 9999s;
  }

  :-moz-autofill, :-moz-autofill-preview {
    filter: grayscale(21%) brightness(88%) contrast(161%) invert(10%) sepia(40%) saturate(206%);
  }

  b, strong {
    font-weight: ${theme.fontWeight.medium};
  }

  a {
    text-decoration: none;
  }

  h1, h2, h3, h4 {
    letter-spacing: 0.3px;
    line-height: 1.2;
  }

  h1 {
    font-size: ${theme.fontSize.pageTitleMobile};

    strong {
      color: ${theme.palette.primary};
      font-weight: ${theme.fontWeight.medium};
    }

    @media ${theme.devices.medium} {
      font-size: ${theme.fontSize.pageTitle};
    }
  }

  h2 {
    font-size: ${theme.fontSize.titleMobile};

    @media ${theme.devices.medium} {
      font-size: ${theme.fontSize.title};
    }
  }

  h3 {
    font-size: ${theme.fontSize.base};
  }

  p {
    font-size: ${theme.fontSize.base};
    letter-spacing: 0.2px;
    line-height: 26px;
    
    @media ${theme.devices.medium} {
      line-height: 28px;
    }
  }

  .svg-sprite {
    bottom: 0;
    position: absolute !important;
    right: 0;
    height: 1px; 
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap;
  }

  .Toastify__toast {
    padding: 0;
    margin: 10px;
  }
  
  .Toastify__toast-container {
    width: 100%;
    max-width: 427px;
  }

  .flatpickr-calendar {
    .flatpickr-months .flatpickr-month,
    .flatpickr-current-month .flatpickr-monthDropdown-months,
    .flatpickr-weekdays,
    span.flatpickr-weekday {
      background-color: ${theme.palette.primary};
    }

    span.flatpickr-weekday {
      color: ${theme.palette.light};
      font-weight: ${theme.fontWeight.medium};
    }

    .flatpickr-day {
      color: ${theme.palette.dark};
      font-weight: ${theme.fontWeight.medium};
    }

    .flatpickr-day.flatpickr-disabled, .flatpickr-day.flatpickr-disabled:hover, .flatpickr-day.prevMonthDay, .flatpickr-day.nextMonthDay, .flatpickr-day.notAllowed, .flatpickr-day.notAllowed.prevMonthDay, .flatpickr-day.notAllowed.nextMonthDay {
      color: ${theme.palette.darkDisabled};
      font-weight: ${theme.fontWeight.light};
    }

    .flatpickr-day.inRange, .flatpickr-day.prevMonthDay.inRange, .flatpickr-day.nextMonthDay.inRange, .flatpickr-day.today.inRange, .flatpickr-day.prevMonthDay.today.inRange, .flatpickr-day.nextMonthDay.today.inRange, .flatpickr-day:hover, .flatpickr-day.prevMonthDay:hover, .flatpickr-day.nextMonthDay:hover, .flatpickr-day:focus, .flatpickr-day.prevMonthDay:focus, .flatpickr-day.nextMonthDay:focus {
      background-color: ${theme.palette.neutralLight1};
      border-color: ${theme.palette.neutralLight1};
    }

    .flatpickr-day.today:hover, .flatpickr-day.today:focus {
      background-color: ${theme.palette.neutral};
      border-color: ${theme.palette.neutral};
    }

    .flatpickr-months .flatpickr-prev-month:hover svg, .flatpickr-months .flatpickr-next-month:hover svg {
      fill: ${theme.palette.dark};
    }

    .flatpickr-day.selected, .flatpickr-day.startRange, .flatpickr-day.endRange, .flatpickr-day.selected.inRange, .flatpickr-day.startRange.inRange, .flatpickr-day.endRange.inRange, .flatpickr-day.selected:focus, .flatpickr-day.startRange:focus, .flatpickr-day.endRange:focus, .flatpickr-day.selected:hover, .flatpickr-day.startRange:hover, .flatpickr-day.endRange:hover, .flatpickr-day.selected.prevMonthDay, .flatpickr-day.startRange.prevMonthDay, .flatpickr-day.endRange.prevMonthDay, .flatpickr-day.selected.nextMonthDay, .flatpickr-day.startRange.nextMonthDay, .flatpickr-day.endRange.nextMonthDay {
      background-color: ${theme.palette.primary};
      border-color: ${theme.palette.primary};
    }

    .flatpickr-time input {
      color: ${theme.palette.dark};
    }

    .flatpickr-time input.flatpickr-hour {
      font-weight: ${theme.fontWeight.medium};
    }

    .flatpickr-time input.flatpickr-minute, .flatpickr-time input.flatpickr-second {
      font-weight: ${theme.fontWeight.light};
    }
  }
  
  // Text editor custom styles
  .rdw-colorpicker-modal {
    height: inherit;
  }
  
  // Carusel custom style
  .carousel .slide {
    background: ${theme.palette.light};;
  }

  [data-reach-dialog-overlay] {
    z-index: 999;
  }

  .grecaptcha-badge {
    z-index: 999;
  }
`;

export default GlobalStyle;
